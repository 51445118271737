import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider, Helmet } from 'react-helmet-async';  // Import Helmet
import reportWebVitals from "./reportWebVitals";
import { GlobalStateProvider } from "./components/GlobalVariable";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
