import balance from "../assets/tools/Balance-Number-Calculator.png";
import bank from "../assets/tools/is-your-bank-account-number-lucky.png";
import daily from "../assets/tools/Numerology-Daily-BioRhythm-Chart.png";
import destiny from "../assets/tools/Destiny-Number-Calculator.png";
import fasting from "../assets/tools/numerology-best-Day-to-fast.png";
import passion from "../assets/tools/Passion-Number-Calculator-Numerology.png";
import house from "../assets/tools/House-Number-Calculator-Numerology.png";
import jersey from "../assets/tools/Jersey-Number-calculator.png";
import karmic from "../assets/tools/Karmic-Debt-number-calculator.png";
import kuacompat from "../assets/tools/Kua-number-compatibility.png";
import kua from "../assets/tools/Calculate-kua-number.png";
import lifepath from "../assets/tools/Life-path-number-calculator.png";
import loshu from "../assets/tools/Create-instant-Lo-Shu-Grid.png";
import business from "../assets/tools/Business-Name-and-numerology-finding.png";
import maturity from "../assets/tools/Maturity-Number-Calculator.png";
import mobile from "../assets/tools/Find-your-lucky-mobile-number.png";
import monthly from "../assets/tools/Numerology-monthly-biorhythmchart.png";
import name from "../assets/tools/Numerology-name-number-calculator.png";
import partner from "../assets/tools/Numerology-compatibility.png";
import personalyear from "../assets/tools/Numerology-persnol-Year-number.png";
import personality from "../assets/tools/Personality-number-calculator.png";
import pinnacle from "../assets/tools/Find-your-Pinnacle-cycle-number.png";
import soulurge from "../assets/tools/Soul-urge-number-Calculator.png";
import subconscious from "../assets/tools/Subconscious-self-number-calculator.png";
import vehicle from "../assets/tools/Lucky-vehicle-number-calculator.png";



const all_images = [
  {
    imageSrc: name, // 0
    link: "/name-number-calculator",
    alt: "Numerology Name Number Calculator Tool to Find Your Name Number",
  },
  {
    imageSrc: loshu, // 1
    link: "/create-your-lo-shu-grid-missing-number-remedies",
    alt: "Numerology Calculator easily create Lo Shu Grid with Missing Number Remedies",
  },
  {
    imageSrc: balance, // 2
    link: "/balance-number-calculator",
    alt: "Numerology Calculator to find your Balance Number",
  },
  {
    imageSrc: bank, // 3
    link: "/is-your-bank-account-number-lucky",
    alt: "Find if your Bank account Number is Lucky for you?",
  },
  {
    imageSrc: daily, // 4
    link: "/daily-numerological-chart",
    alt: "Numerology Calculator for Daily Numerological Chart",
  },
  {
    imageSrc: destiny, // 5
    link: "/destiny-number-calculator",
    alt: "Numerology Destiny Number Calculator Tool",
  },
  {
    imageSrc: fasting, // 6
    link: "/personal-fast-day-calculator-numerology",
    alt: "Numerology Personal Fast Day Calculator Tool",
  },
  {
    imageSrc: passion, // 7
    link: "/hidden-passion-number-calculator",
    alt: "Numerology Hidden Passion Number Calculator Tool",
  },
  {
    imageSrc: house, // 8
    link: "/is-your-house-number-lucky-with-remedies",
    alt: "Find if your House Number is Lucky with Remedies",
  },
  {
    imageSrc: jersey, // 9
    link: "/jersey-number-calculator",
    alt: "Numerology Jersey Number Calculator Tool",
  },
  {
    imageSrc: karmic, // 10
    link: "/what-are-karmic-debt-numbers-in-numerology",
    alt: "Learn about Karmic Debt Numbers in Numerology",
  },
  {
    imageSrc: kuacompat, // 11
    link: "/numerology-kua-compatibility",
    alt: "Numerology Kua Compatibility Calculator Tool",
  },
  {
    imageSrc: kua, // 12
    link: "/kua-number-calculator",
    alt: "Numerology Kua Number Calculator Tool",
  },
  {
    imageSrc: lifepath, // 13
    link: "/life-path-number-calculator",
    alt: "Numerology Life Path Number Calculator Tool",
  },
  {
    imageSrc: business, // 14
    link: "/lucky-business-name-calculator",
    alt: "Numerology Lucky Business Name Calculator Tool",
  },
  {
    imageSrc: maturity, // 15
    link: "/numerology-maturity-number-calculator",
    alt: "Numerology Maturity Number Calculator Tool",
  },
  {
    imageSrc: mobile, // 16
    link: "/is-your-mobile-number-lucky",
    alt: "Find if your Mobile Number is Lucky for you?",
  },
  {
    imageSrc: monthly, // 17
    link: "/personalized-monthly-biorhythm-chart",
    alt: "Personalized Monthly Biorhythm Chart in Numerology",
  },
  {
    imageSrc: partner, // 18
    link: "/numerology-partner-compatibility",
    alt: "Numerology Partner Compatibility Calculator Tool",
  },
  {
    imageSrc: personalyear, // 19
    link: "/numerology-personal-year-calculator",
    alt: "Numerology Personal Year Calculator Tool",
  },
  {
    imageSrc: personality, // 20
    link: "/personality-number-calculator",
    alt: "Numerology Personality Number Calculator Tool",
  },
  {
    imageSrc: pinnacle, // 21
    link: "/pinnacle-number-calculator",
    alt: "Numerology Pinnacle Number Calculator Tool",
  },
  {
    imageSrc: soulurge, // 22
    link: "/soul-urge-number-calculator",
    alt: "Numerology Soul Urge Number Calculator Tool",
  },
  {
    imageSrc: subconscious, // 23
    link: "/subconscious-self-number-calculator",
    alt: "Numerology Subconscious Self Number Calculator Tool",
  },
  {
    imageSrc: vehicle, // 24
    link: "/is-your-vehicle-number-lucky-with-remedies",
    alt: "Find if your Vehicle Number is Lucky with Remedies",
  },
];

export { all_images };
